@charset "utf-8";

* { margin: 0; padding: 0; }

@font-face { font-family: "Lato", Helvetica, Arial, sans-serif; }

html, body {
  background: #f4f4f4;
  color: #55626d;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-size: 20px;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  border-bottom: dotted 2px #cad1d8;
  color: #383f49;
  text-decoration: none;
}
a:hover, a:focus, a:active {
  border-bottom: none;
  color: #849419;
  text-decoration: none;
}
a.icon {
  border-bottom: none;
}

hr {
  width: 100%;
  height: 2px;
  margin: 15px auto;
  border: 5px;
  background-color: #d1d1d1;
  color: #d1d1d1;
}

h1, h2, h3, h4, h5, h6 { color: #849419; font-family: "Lato", Helvetica, Arial, sans-serif; }
h1 { font-weight: 800;  }
h2, h3, h4 { font-weight: 400; }

h1 { font-size: 3rem; text-align: center; }
h2 { font-size: 2rem; }

header {
  border-top: 5px solid #bed42a;
  padding-top: 20px;
}

.dot-separator {
  width: 8px;
  height: 8px;
  left: 50%;
  margin: 15px auto;
  border-radius: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background: #bed42a;
  box-shadow: 24px 0 #bed42a, -24px 0 #bed42a;
}

.profile-picture {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 15px;
}
.profile-picture img {
  width: 200px;
  border: 5px solid #ccc;

  -webkit-filter: grayscale(65%);
  -moz-filter: grayscale(65%);
  -o-filter: grayscale(65%);
  filter: grayscale(65%);
}
.profile-social, .profile-cv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin-bottom: 25px;
}
.profile-social {
  text-align: center;
}
.profile-cv {
  justify-content: flex-start;
  align-items: flex-end;
}
.profile-cv .downloads-heading {
  color: #849419;
  font-weight: bold;
}
.profile-cv a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  gap: 4px;
}

.portfolio {
  margin-top: 25px;
  margin-bottom: 25px;
}
.portfolio-filter {
  padding-left: 0;
}
.portfolio-filter li {
  display: inline;
  margin-right: 15px;
  border-bottom: dotted 2px #cad1d8;
  color: #383f49;
  cursor: pointer;
}
  .portfolio-filter li:hover,
  .portfolio-filter li:focus,
  .portfolio-filter li:active,
  .portfolio-filter .selected {
      border-bottom: none;
      color: #849419;
      text-decoration: none;
    }
.portfolio-grid {
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.portfolio-grid-column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}
.portfolio-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  width: 100%;
  margin-bottom: 30px;
}
.portfolio-card {
  cursor: pointer;
  height: auto;
  margin-bottom: 16px;
  padding: 8px;
}
  .portfolio-card img {
    border-radius: 0;
  }
.portfolio-card .card-body {
  padding: 8px 0;
  font-size: 0.85rem;
  text-align: center;
}

.modal-container {
  max-width: 80%;
}
.modal-body .img-holder {
  margin-bottom: 12px;
  text-align: center;
}
.modal-body img {
  height: auto;
}
.modal-body p {
  margin-bottom: 0;
}

.not-found {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.not-found h1 { font-size: 5rem; font-weight: bold; }
.not-found h3 { color: #55626d;font-size: 2.6rem; }
.not-found .back-to-home {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-bottom: none;
  font-size: 1.5rem;
}

footer {
  margin: 40px 0 15px;
  padding: 15px 0;
  font-size: 0.85rem;
  text-align: center;
}
footer p {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
